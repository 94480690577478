<!-- @Author: Yu_Bo -->
<template>
	<div class='creation_main'>
		作品管理
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{

			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {},
	}
</script>

<style lang='scss' scoped>
	.creation_main{

	}
</style>
